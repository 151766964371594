import { GoogleAuthProvider, signInWithPopup, signOut } from "@firebase/auth";
import { auth, db } from "../config/firebase";
import { doc, getDoc, setDoc } from "@firebase/firestore";

const googleProvider = new GoogleAuthProvider();

export const signInWithGoogle = async () => {
  try {
    const res = await signInWithPopup(auth, googleProvider);
    const user = res.user;
    const userRef = doc(db, "users", user.uid); // Create a reference to the user document with the uid

    const docSnap = await getDoc(userRef);
    if (!docSnap.exists()) {
      // User doesn't exist in Firestore, so create a new document with user.uid as the ID
      await setDoc(userRef, {
        uid: user.uid,
        name: user.displayName,
        authProvider: "google",
        email: user.email,
        approved: false,
        role: "user",
      });
    }
  } catch (err: any) {
    console.error(err);
    alert(err.message);
  }
};

export const logout = () => {
  signOut(auth);
};
