import React, { useEffect, useState } from "react";
// Import Cloudscape components
import {
  Button,
  Input,
  Select,
  FormField,
  Header,
  Box,
  SpaceBetween,
} from "@cloudscape-design/components";
import {
  createStartUp,
  deleteStartUp,
  subscribeToStartUp,
} from "../api/startup";

export type CostItem = {
  id: string;
  description: string;
  amount: number;
  category: string;
};

const StartUpCost = () => {
  const [costItems, setCostItems] = useState<CostItem[]>([]);
  const [description, setDescription] = useState<string>("");
  const [amount, setAmount] = useState<string>("0");
  const [category, setCategory] = useState<any>({
    label: "Select Category",
    value: "",
    disabled: true,
  });

  const options = [
    { label: "Select Category", value: "", disabled: true },
    { label: "Equipment", value: "equipment" },
    { label: "Rent", value: "rent" },
    { label: "Marketing", value: "marketing" },
    { label: "Licenses", value: "licenses" },
  ];

  const addCostItem = async () => {
    const newCostItem: CostItem = {
      id: "",
      description,
      amount: parseFloat(amount),
      category: category.value,
    };
    const id = await createStartUp(newCostItem);
    newCostItem.id = id;
    setCostItems([...costItems, newCostItem]);
    setDescription("");
    setAmount("0");
    setCategory("");
  };

  const handleDeleteRow = async (item: CostItem) => {
    setCostItems((x) => x.filter((i) => i.id !== item.id));
    await deleteStartUp(item.id);
  };

  useEffect(() => {
    const unsubscribe = subscribeToStartUp(setCostItems);

    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <SpaceBetween direction="vertical" size="m">
      <Header>Startup Costs</Header>

      <FormField label="Description">
        <Input
          value={description}
          onChange={(e) => setDescription(e.detail.value)}
          placeholder="Enter description"
        />
      </FormField>
      <FormField label="Amount">
        <Input
          type="number"
          value={amount}
          onChange={(e) => setAmount(e.detail.value)}
          placeholder="Enter amount"
        />
      </FormField>
      <FormField label="Category">
        <Select
          selectedOption={category}
          onChange={({ detail }) => setCategory(detail.selectedOption)}
          options={options}
        />
      </FormField>
      <Button
        disabled={!category?.value || !amount || !description}
        onClick={addCostItem}
      >
        Add Cost Item
      </Button>
      <Box>
        <Box margin={{ bottom: "m" }}>
          Total Startup Costs: €
          {costItems.reduce((acc, item) => acc + item.amount, 0)}
        </Box>
        <SpaceBetween size="xxxs">
          {costItems.map((item) => (
            <Box key={item.id}>
              {`${item.description} - €${item.amount} (${
                options.find((x) => x.value === item.category)?.label
              })`}{" "}
              <Button
                variant="icon"
                iconName="remove"
                onClick={() => handleDeleteRow(item)}
              />
            </Box>
          ))}
        </SpaceBetween>
      </Box>
    </SpaceBetween>
  );
};

export default StartUpCost;
