export interface IElo {
  id: any; // This can be any type that you use for ID.
  ranking: number; // Ensure the rating is always expected to be a number.
}

export const rankSort = (a: IElo, b: IElo) => {
  // Check if 'ranking' is missing and handle accordingly
  if (a.ranking == null) return 1; // Move items without a ranking to the end
  if (b.ranking == null) return -1; // Move items without a ranking to the end

  // Compare rankings to sort in descending order
  return b.ranking - a.ranking;
};

function rate<T extends IElo, K extends keyof T & "rating">(
  players: T[],
  winnerId: T["id"],
  kFactor: number = 32
): T[] {
  let newPlayers = players.map((player) => ({ ...player }));

  const winnerIndex = players.findIndex((player) => player.id === winnerId);
  if (winnerIndex === -1) {
    throw new Error("Winner not found in the player list.");
  }

  players.forEach((player, index) => {
    if (index !== winnerIndex) {
      const result = 1; // Winner wins
      const expectedScoreWinner =
        1 /
        (1 +
          Math.pow(
            10,
            ((player.ranking as number) -
              (players[winnerIndex].ranking as number)) /
              400
          ));
      const expectedScoreLoser =
        1 /
        (1 +
          Math.pow(
            10,
            ((players[winnerIndex].ranking as number) -
              (player.ranking as number)) /
              400
          ));

      newPlayers[winnerIndex].ranking +=
        kFactor * (result - expectedScoreWinner);
      newPlayers[index].ranking -= kFactor * expectedScoreLoser;
    }
  });

  // Properly round the updated ratings
  newPlayers.forEach((player) => {
    player.ranking = Math.round(player.ranking as number);
  });

  return newPlayers;
}

// Export the rate function as a named export
export { rate };
