import {
  Button,
  ContentLayout,
  Header,
  Tabs,
} from "@cloudscape-design/components";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useLocalStorage } from "react-use";
import StartUpCost from "../components/StartUpCost";

const Financial = () => {
  const [tab, setTab] = useLocalStorage("ftab", "suc");

  return (
    <ContentLayout
      disableOverlap
      header={
        <Header
          variant="h1"
          description={
            "Hint: This module might not be useful if you are not starting a meadery"
          }
        >
          Financial
        </Header>
      }
    >
      <Tabs
        onChange={({ detail }) => setTab(detail.activeTabId)}
        activeTabId={tab}
        tabs={[
          {
            label: "Start up cost",
            id: "suc",
            content: <StartUpCost />,
          },
          {
            label: "Income Statement",
            id: "income",
            content: "Second tab content area",
          },
          {
            label: "Cash Flow Statement",
            id: "cash",
            content: "Second tab content area",
          },
          {
            label: "Balance Sheet",
            id: "balance",
            content: "Second tab content area",
          },
          {
            label: "Break-even Analysis",
            id: "break",
            content: "Second tab content area",
          },
          {
            label: "Sales Forecast",
            id: "sales",
            content: "Second tab content area",
          },
          {
            label: "Budget",
            id: "budget",
            content: "Third tab content area",
          },
        ]}
      />
    </ContentLayout>
  );
};

export default Financial;
