import { db } from "../config/firebase";
import { collection, getDocs, doc, setDoc } from "@firebase/firestore";

const defaultMenuItems = [
  { title: "Inventory", type: "link", href: "/inventory", ranking: 1500 },
  { title: "Recipes", type: "link", href: "/recipes", ranking: 1500 },
  { title: "Batches", type: "link", href: "/batches", ranking: 1500 },
  {
    title: "Shopping List",
    type: "link",
    href: "/shopping-list",
    ranking: 1500,
  },
  { title: "Financial", type: "link", href: "/financial", ranking: 1500 },
];

export async function initializeMenuItems(): Promise<void> {
  try {
    const menuCollection = collection(db, "menuItems");
    const existingItemsSnapshot = await getDocs(menuCollection);
    const existingItems = new Set();
    existingItemsSnapshot.forEach((doc) => {
      // Assuming we use 'href' as the unique identifier for each menu item
      existingItems.add(doc.data().href);
    });

    for (const item of defaultMenuItems) {
      if (!existingItems.has(item.href)) {
        // Generate an ID from the href, replacing slashes with dashes, or use a custom method
        const itemId = item.href.replace(/\//g, "-");
        const docRef = doc(menuCollection, itemId);
        await setDoc(docRef, item);
      }
    }
  } catch (error) {
    console.error("Error initializing menu items:", error);
    throw error; // Re-throw the error after logging it
  }
}
