// fetchUnapprovedUsers.ts

import {
  collection,
  doc,
  getDocs,
  onSnapshot,
  query,
  updateDoc,
  where,
} from "@firebase/firestore";

import { UserData } from "../models/UserData"; // Your user data type
import { db } from "../config/firebase"; // Your Firestore instance

export const subscribeToUnapprovedUsers = (
  onUpdate: (users: UserData[]) => void
) => {
  const unapprovedUsersQuery = query(
    collection(db, "users"),
    where("approved", "==", false),
    where("denied", "==", false)
  );

  const unsubscribe = onSnapshot(
    unapprovedUsersQuery,
    (querySnapshot) => {
      const unapprovedUsers: UserData[] = querySnapshot.docs.map((doc) => ({
        ...(doc.data() as UserData),
        id: doc.id,
      }));
      onUpdate(unapprovedUsers);
    },
    (error) => {
      console.error("Error fetching unapproved users:", error);
    }
  );

  return unsubscribe;
};

export const fetchUnapprovedUsers = async (): Promise<UserData[]> => {
  const unapprovedUsersQuery = query(
    collection(db, "users"),
    where("approved", "==", false)
  );

  try {
    const querySnapshot = await getDocs(unapprovedUsersQuery);
    const unapprovedUsers: UserData[] = querySnapshot.docs.map((doc) => ({
      ...(doc.data() as UserData),
      id: doc.id, // Assuming you want to keep the document ID
    })) as UserData[];
    return unapprovedUsers;
  } catch (error) {
    console.error("Error fetching unapproved users:", error);
    throw new Error("Failed to fetch unapproved users");
  }
};

/**
 * Approve a user by their UID.
 * @param uid The UID of the user to approve.
 */
export const approveUserByUid = async (uid: string): Promise<void> => {
  try {
    const userRef = doc(db, "users", uid);
    await updateDoc(userRef, { approved: true });
  } catch (error) {
    console.error(`Error approving user with UID ${uid}:`, error);
    throw error; // Or handle this error as needed
  }
};

/**
 * Change user role.
 * @param uid The UID of the user to change.
 * @param role The role to set.
 */
export const setUserRoleByUid = async (
  uid: string,
  role: string
): Promise<void> => {
  try {
    const userRef = doc(db, "users", uid);
    await updateDoc(userRef, { role });
  } catch (error) {
    console.error(`Error setting role for ${uid}:`, error);
    throw error; // Or handle this error as needed
  }
};

export const updateUser = async (
  uid: string,
  data: Partial<UserData>
): Promise<void> => {
  try {
    const userRef = doc(db, "users", uid);
    await updateDoc(userRef, data);
  } catch (error) {
    console.error(`Error updating user ${uid}:`, error);
    throw error; // Or handle this error as needed
  }
};
