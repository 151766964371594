import {
  Badge,
  Button,
  ContentLayout,
  Header,
  ProgressBar,
  SpaceBetween,
  Table,
} from "@cloudscape-design/components";
import { Link, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { addDaysBySteps, toDate } from "../utils/timestamp";
import {
  calculateABV,
  calculateDateDay,
  calculateFinalABV,
} from "../utils/steps";
import { costPerBottle, costPerUnit, costTotal } from "../utils/cost";
import { fetchBatches, subscribeToBatches } from "../api/batches";

import { Batch } from "../models/Batch";
import DisplayImage from "../components/DisplayImage";
import { GravitySample } from "../models/Sampling";
import { Recipe } from "../models/Recipe";
import { daysUntilDate } from "../utils/date";
import { fetchRecipes } from "../api/recipes";
import { getLatestGravitySample } from "../api/sample";
import { useLocalStorage } from "react-use";

const Batches = () => {
  const navigate = useNavigate();
  const [batches, setBatches] = useState<Batch[]>([]);
  const [sampleRecords, setSampleRecords] = useState<
    { sample: GravitySample; id: string }[]
  >([]);

  const [showArchived, setShowArchived] = useLocalStorage(
    "showArchived",
    false
  );

  useEffect(() => {
    fetchBatches().then(setBatches);
    const unsubscribe = () => subscribeToBatches(setBatches);
    return () => {
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    const fetchSamples = async () => {
      const batchesWithId = await Promise.all(
        batches.map(async (batch) => {
          const sample = await getLatestGravitySample(batch.id);
          return { sample, id: batch.id };
        })
      );
      setSampleRecords(batchesWithId);
    };
    fetchSamples();
  }, [batches]);

  return (
    <ContentLayout
      disableOverlap
      header={
        <Header
          variant="h1"
          description={"Hint: Have the recepies and ingredients ready"}
          actions={
            <SpaceBetween size="m" direction="horizontal">
              <Button
                iconName="add-plus"
                variant="primary"
                onClick={() => navigate("/batches/new")}
              >
                Start a batch
              </Button>
              {showArchived ? (
                <Button
                  variant="primary"
                  iconName="check"
                  onClick={() => setShowArchived(false)}
                >
                  Hide archived
                </Button>
              ) : (
                <Button onClick={() => setShowArchived(true)}>
                  Show archived
                </Button>
              )}
            </SpaceBetween>
          }
        >
          Batches
        </Header>
      }
    >
      <div style={{ marginTop: 10 }}>
        <Table
          wrapLines
          variant="embedded"
          items={
            showArchived
              ? batches
              : batches.filter((b) => b.status !== "archived")
          }
          columnDefinitions={[
            {
              header: "Image",
              cell: (item) => (
                <DisplayImage
                  fileName="image"
                  filePath={"recipes/" + item.recipeId}
                  round
                  altText="Recipe image"
                  style={{ height: 50, width: 50 }}
                />
              ),
            },
            {
              header: "Name",
              cell: (item) => (
                <Link to={`/batches/${item.id}`}>
                  <strong>{item.name ?? "N/A"}</strong>
                </Link>
              ),
              isRowHeader: true,
            },
            {
              header: "Status",
              cell: (item) => (
                <Badge
                  color={
                    item.status === "in-progress"
                      ? "blue"
                      : item.status === "completed"
                      ? "green"
                      : "grey"
                  }
                >
                  <span style={{ whiteSpace: "nowrap" }}>
                    {(
                      (item?.status ?? "planned").charAt(0).toUpperCase() +
                      (item?.status ?? "planned").slice(1)
                    ).replace("-", " ")}
                  </span>
                </Badge>
              ),
            },
            {
              header: "ABV (now)",
              cell: (item) => {
                const sample = sampleRecords.find(
                  (s) => s.id === item.id
                )?.sample;
                return <>{sample ? calculateABV(sample) + "%" : ""}</>;
              },
            },
            {
              header: "ABV",
              cell: (item) => {
                const sample = sampleRecords.find(
                  (s) => s.id === item.id
                )?.sample;
                return <>{sample ? calculateFinalABV(sample) + "%" : ""}</>;
              },
            },
            {
              header: "Next action",
              cell: (item) => {
                // get current step estimated end date
                let date = !!item.recipe?.steps[item.currentStepIndex].end
                  ? toDate(
                      item.recipe?.steps[item.currentStepIndex].end ??
                        new Date()
                    )
                  : null;
                // if date is null, calculate it from the current step duration
                if (!date) {
                  date = addDaysBySteps(
                    toDate(item.startDate),
                    (item?.recipe?.steps ?? []).slice(
                      0,
                      (item.currentStepIndex ?? 0) + 1
                    )
                  );
                }

                return <>{daysUntilDate(date).toFixed(0)}</>;
              },
            },
            {
              header: "Step",
              cell: (item) => (
                <>{item.recipe?.steps[item.currentStepIndex].name ?? "N/A"}</>
              ),
            },
            {
              header: "Progress",
              cell: (item) => {
                const { progress } = calculateDateDay(item, new Date());
                return (
                  <div style={{ width: 200 }}>
                    <ProgressBar value={progress} />
                  </div>
                );
              },
            },
            {
              header: "Recipe",
              cell: (item) => <>{item.recipe?.name ?? "N/A"}</>,
            },
            {
              header: "Quantity",
              cell: (item) => <>{item.quantity ?? "N/A"}</>,
            },
            {
              header: "Cost L",
              cell: (item: Batch) => (
                <span style={{ whiteSpace: "nowrap" }}>
                  €{costPerUnit(item).toFixed(2)}
                </span>
              ),
            },
            {
              header: "Started",
              cell: (item) => {
                const date = toDate(item.startDate);
                return <>{date?.toLocaleDateString() ?? ""}</>;
              },
            },
            {
              header: "Completed",
              cell: (item) => (
                <>
                  {item?.endDate
                    ? toDate(item.endDate)?.toLocaleDateString()
                    : ""}
                </>
              ),
            },
          ]}
        />
      </div>
    </ContentLayout>
  );
};

export default Batches;
