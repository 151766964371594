import NotApproved, { Denied } from "../pages/NotApproved";
import { Route, Routes } from "react-router-dom";

import BatchPage from "../pages/BatchPage";
import BatchRecipePage from "../pages/BatchRecipePage";
import Batches from "../pages/Batches";
import Home from "../pages/Home";
import Inventory from "../pages/Inventory";
import InventoryItemPage from "../pages/InventoryItemPage";
import React from "react";
import RecipePage from "../pages/RecipePage";
import Recipes from "../pages/Recipes";
import { ShoppingList } from "../pages/ShoppingList";
import { useAuthContext } from "../contexts/AuthContext";
import Financial from "../pages/Financial";

export const Routing = () => {
  const { currentUser, userObject } = useAuthContext();

  if (!currentUser) {
    return (
      <Routes>
        <Route path="/" element={<Home />} />
      </Routes>
    );
  }

  if (userObject?.denied) {
    return (
      <Routes>
        <Route path="/" element={<Denied />} />
      </Routes>
    );
  }

  if (!userObject?.approved) {
    return (
      <Routes>
        <Route path="/" element={<NotApproved />} />
      </Routes>
    );
  }

  return (
    <Routes>
      <Route path="/inventory" element={<Inventory />} />
      <Route path="/inventory/:id" element={<InventoryItemPage />} />
      <Route path="/recipes" element={<Recipes />} />
      <Route path="/recipes/:id" element={<RecipePage />} />
      <Route path="/batches" element={<Batches />} />
      <Route path="/batches/:id" element={<BatchPage />} />
      <Route path="/batches/:id/recipe/:recipe" element={<BatchRecipePage />} />
      <Route path="/batches/:id/modal/:modal" element={<BatchPage />} />
      <Route path="/batches/:id/modal/:modal/:s" element={<BatchPage />} />
      <Route path="/shopping-list" element={<ShoppingList />} />
      <Route path="/financial" element={<Financial />} />
      <Route path="/" element={<Home />} />
    </Routes>
  );
};
