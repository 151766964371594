import { collection, getDocs, onSnapshot } from "@firebase/firestore";

import { Batch } from "../models/Batch";
import { db } from "../config/firebase"; // Adjust this import to your Firebase config
import { toDate } from "../utils/timestamp";

export const fetchBatch = async (id: string): Promise<Batch | null> => {
  try {
    const querySnapshot = await getDocs(collection(db, "batches"));
    const fetchedBatch = querySnapshot.docs
      .map((doc) => ({ ...doc.data(), id: doc.id } as Batch))
      .find((batch) => batch.id === id);
    return fetchedBatch ?? null;
  } catch (error) {
    console.error("Error fetching batch:", error);
    throw new Error("Failed to fetch batch");
  }
};

export const fetchBatches = async (): Promise<Batch[]> => {
  try {
    const querySnapshot = await getDocs(collection(db, "batches"));
    const fetchedRecipes: Batch[] = querySnapshot.docs.map(
      (doc) =>
        ({
          ...doc.data(),
          id: doc.id,
        } as Batch)
    );
    const sortedByStartDate = fetchedRecipes.sort(
      (a, b) => toDate(a.startDate).getTime() - toDate(b.startDate).getTime()
    );
    return sortedByStartDate;
  } catch (error) {
    console.error("Error fetching recipes:", error);
    throw new Error("Failed to fetch recipes");
  }
};

export const fetchBatchesByStatus = async (
  status: string
): Promise<Batch[]> => {
  try {
    const querySnapshot = await getDocs(collection(db, "batches"));
    const fetchedRecipes: Batch[] = querySnapshot.docs
      .map((doc) => ({ ...doc.data(), id: doc.id } as Batch))
      .filter((batch) => batch.status === status);
    const sortedByStartDate = fetchedRecipes.sort(
      (a, b) => toDate(a.startDate).getTime() - toDate(b.startDate).getTime()
    );
    return sortedByStartDate;
  } catch (error) {
    console.error("Error fetching recipes:", error);
    throw new Error("Failed to fetch recipes");
  }
};

export const subscribeToBatchesByStatus = (
  status: string,
  onUpdate: (batches: Batch[]) => void
) => {
  const batchesCollectionRef = collection(db, "batches"); // Replace 'batches' with your actual collection name

  const unsubscribe = onSnapshot(
    batchesCollectionRef,
    (querySnapshot) => {
      const fetchedBatches: Batch[] = querySnapshot.docs
        .map((doc) => ({ ...doc.data(), id: doc.id } as Batch))
        .filter((batch) => batch.status === status);
      onUpdate(fetchedBatches);
    },
    (error) => {
      console.error("Error fetching batches:", error);
    }
  );

  return unsubscribe;
};

export const subscribeToBatches = (onUpdate: (batches: Batch[]) => void) => {
  const batchesCollectionRef = collection(db, "batches"); // Replace 'batches' with your actual collection name

  const unsubscribe = onSnapshot(
    batchesCollectionRef,
    (querySnapshot) => {
      const fetchedBatches: Batch[] = querySnapshot.docs.map(
        (doc) =>
          ({
            ...doc.data(),
            id: doc.id,
            startDate: doc.data().startDate.toDate(),
            endDate: doc.data().endDate?.toDate(),
          } as Batch)
      );
      onUpdate(fetchedBatches);
    },
    (error) => {
      console.error("Error fetching batches:", error);
    }
  );

  return unsubscribe;
};

export const getBatchCount = async (): Promise<number> => {
  try {
    const querySnapshot = await getDocs(collection(db, "batches"));
    return querySnapshot.size;
  } catch (error) {
    console.error("Error fetching batch count:", error);
    throw new Error("Failed to fetch batch count");
  }
};
