import {
  Badge,
  Button,
  ContentLayout,
  Header,
  Table,
} from "@cloudscape-design/components";
import { Link, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { deleteRecipe, fetchRecipes, subscribeToRecipes } from "../api/recipes";
import { hexToRgb, hexToRgbObj } from "../utils/color";

import DisplayImage from "../components/DisplayImage";
import { Recipe } from "../models/Recipe";

const Recipes = () => {
  const navigate = useNavigate();
  const [recipes, setRecipes] = useState<Recipe[]>([]);

  useEffect(() => {
    fetchRecipes().then(setRecipes);
    const unsubscribe = () => subscribeToRecipes(setRecipes);
    return () => {
      unsubscribe();
    };
  }, []);

  const deleteItem = async (item: Recipe) => {
    const confirm = window.confirm(
      `Are you sure you want to delete ${item.name}?`
    );
    if (!confirm) return;

    await deleteRecipe(item.id);
  };

  return (
    <ContentLayout
      disableOverlap
      header={
        <Header
          variant="h1"
          description={"Hint: Create ingredients before you create recipes"}
          actions={
            <Button
              iconName="add-plus"
              variant="primary"
              onClick={() => navigate("/recipes/new")}
            >
              Create recipe
            </Button>
          }
        >
          Recipes
        </Header>
      }
    >
      <div style={{ marginTop: 10 }}>
        <Table
          wrapLines
          variant="embedded"
          items={recipes}
          columnDefinitions={[
            {
              header: "Image",
              cell: (item) => (
                <DisplayImage
                  fileName="image"
                  filePath={"recipes/" + item.id}
                  round
                  altText="Recipe image"
                  style={{ height: 50, width: 50 }}
                />
              ),
            },
            {
              header: "Name",
              cell: (item) => (
                <Link to={`/recipes/${item.id}`}>
                  <strong>{item.name}</strong>
                </Link>
              ),
              isRowHeader: true,
            },
            {
              header: "Style",
              cell: (item) => {
                const isColorLight = (color: string) => {
                  if (!color) return true;
                  const rgb = hexToRgbObj(color);
                  return (rgb.r * 299 + rgb.g * 587 + rgb.b * 114) / 1000 > 128;
                };

                return (
                  <div
                    style={{
                      borderRadius: 4,
                      color: isColorLight(item.color ?? "#f5f5f5")
                        ? "black"
                        : "white",
                      backgroundColor: item.color ?? "whitesmoke",
                      padding: 4,
                    }}
                  >
                    {item.style}
                  </div>
                );
              },
            },
            {
              header: "Description",
              cell: (item) => <>{item.description}</>,
            },
            {
              header: "Ingredients",
              cell: (item) => (
                <div
                  style={{
                    display: "flex",
                    gap: 2,
                    flexWrap: "wrap",
                  }}
                >
                  {item.ingredients.map((ingredient) => (
                    <span
                      key={ingredient.item.id}
                      style={{ whiteSpace: "nowrap" }}
                      onClick={() =>
                        navigate(`/Inventory/${ingredient.item.id}`)
                      }
                    >
                      <Badge color="blue" className="pointer">
                        {ingredient.item.name.split("(")[0].split("-")[0]}
                      </Badge>
                    </span>
                  ))}
                </div>
              ),
            },
            {
              header: "Steps",
              cell: (item) => <>{item.steps.length}</>,
            },
            {
              header: "Actions",
              cell: (item) => (
                <>
                  <Button
                    iconName="remove"
                    variant="inline-link"
                    onClick={() => deleteItem(item)}
                  />
                </>
              ),
            },
          ]}
        />
      </div>
    </ContentLayout>
  );
};

export default Recipes;
