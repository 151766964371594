import {
  Autosuggest,
  Box,
  Button,
  ContentLayout,
  Form,
  FormField,
  Header,
  Input,
  Modal,
  SpaceBetween,
  Textarea,
} from "@cloudscape-design/components";
import React, { useEffect, useState } from "react";
import { doc, updateDoc } from "@firebase/firestore";
import { useNavigate, useParams } from "react-router-dom";

import { Batch } from "../models/Batch";
import InventoryItem from "../models/InventoryItem";
import { db } from "../config/firebase";
import { fetchBatch } from "../api/batches";
import { fetchInventoryItems } from "../api/ingredients";
import { initalBatch } from "./BatchPage";
import { initalRecipe } from "./RecipePage";

const RecipePage = () => {
  let { id } = useParams();
  const navigate = useNavigate();

  const [ingredients, setIngredients] = useState<InventoryItem[]>([]);

  const [item, setItem] = useState<Batch | null>(null);
  const [validate, setValidate] = useState<boolean>(false);

  const [batchSize, setBatchSize] = useState<string>("");

  const [visibleI, setVisibleI] = useState(false);
  const [addIngredient, setAddIngredient] = useState("");
  const [addQuantity, setAddQuantity] = useState("");

  const [editStep, setEditStep] = useState<number>(-1);

  const [visibleS, setVisibleS] = useState(false);
  const [addStepName, setAddStepName] = useState("");
  const [addStepDescription, setAddStepDescription] = useState("");
  const [addStepDuration, setAddStepDuration] = useState("");
  const [addStepTemperature, setAddStepTemperature] = useState("");
  const [addStepGravity, setAddStepGravity] = useState("");

  useEffect(() => {
    if (!id) return;
    fetchBatch(id).then((i) => {
      setItem(i);
      setBatchSize(i?.recipe?.batchSize.toString() ?? "");
      if (i === null) {
        navigate("/batches/" + id);
      }
    });
  }, [id, navigate]);

  useEffect(() => {
    fetchInventoryItems().then((items) => {
      setIngredients(items);
    });
  }, []);

  const updateItem = async (item: Batch) => {
    try {
      const itemRef = doc(db, "batches", item.id);
      await updateDoc(itemRef, item);
    } catch (error) {
      console.error("Error updating inventory item:", error);
      // Handle the error (e.g., show an error message to the user)
    }
  };

  const handleEditStep = (stepIndex: number) => {
    setEditStep(stepIndex);
    const step = item?.recipe?.steps[stepIndex];
    if (!step) return;
    setAddStepName(step.name);
    setAddStepDescription(step.description ?? "");
    setAddStepDuration(step.duration.toString());
    setAddStepTemperature(step.temperature?.toString() ?? "");
    setAddStepGravity(step.gravity?.toString() ?? "");

    setVisibleS(true);
  };

  const submitForm = async (stay = false) => {
    setValidate(true);

    if (item === null || !item.recipe) return;
    if (item.recipe?.name === "") return;
    if (item.recipe?.style === "") return;

    updateItem(item);

    if (!stay) navigate("/batches/" + id);
  };

  const shiftIngredientUp = (ingredientIndex: number) => {
    setItem((prevItem) => {
      if (prevItem === null) {
        return null;
      } else {
        const newIngredients = [...(prevItem.recipe?.ingredients ?? [])];
        const [removed] = newIngredients.splice(ingredientIndex, 1);
        newIngredients.splice(ingredientIndex - 1, 0, removed);
        return {
          ...initalBatch,
          ...prevItem,
          recipe: {
            ...initalRecipe,
            ...prevItem.recipe,
            ingredients: newIngredients,
          },
        };
      }
    });
  };

  const removeStep = (stepIndex: number) => {
    setItem((prevItem) => {
      if (prevItem === null) {
        return null;
      } else {
        return {
          ...initalBatch,
          ...prevItem,
          recipe: {
            ...initalRecipe,
            ...prevItem.recipe,
            steps:
              prevItem.recipe?.steps.filter((_, i) => i !== stepIndex) ?? [],
          },
        };
      }
    });
  };

  const shiftIngredientDown = (ingredientIndex: number) => {
    setItem((prevItem) => {
      if (prevItem === null) {
        return null;
      } else {
        const newIngredients = [...(prevItem.recipe?.ingredients ?? [])];
        const [removed] = newIngredients.splice(ingredientIndex, 1);
        newIngredients.splice(ingredientIndex + 1, 0, removed);
        return {
          ...initalBatch,
          ...prevItem,
          recipe: {
            ...initalRecipe,
            ...prevItem.recipe,
            ingredients: newIngredients,
          },
        };
      }
    });
  };

  const removeIngredient = (ingredientIndex: number) => {
    setItem((prevItem) => {
      if (prevItem === null) {
        return null;
      } else {
        return {
          ...initalBatch,
          ...prevItem,
          recipe: {
            ...initalRecipe,
            ...prevItem.recipe,
            ingredients:
              prevItem.recipe?.ingredients.filter(
                (_, i) => i !== ingredientIndex
              ) ?? [],
          },
        };
      }
    });
  };

  const shiftStepsUp = (stepIndex: number) => {
    setItem((prevItem) => {
      if (prevItem === null) {
        return null;
      } else {
        const newSteps = [...(prevItem.recipe?.steps ?? [])];
        const [removed] = newSteps.splice(stepIndex, 1);
        newSteps.splice(stepIndex - 1, 0, removed);
        return {
          ...initalBatch,
          ...prevItem,
          recipe: {
            ...initalRecipe,
            ...prevItem.recipe,
            steps: newSteps,
          },
        };
      }
    });
  };

  const shiftStepsDown = (stepIndex: number) => {
    setItem((prevItem) => {
      if (prevItem === null) {
        return null;
      } else {
        const newSteps = [...(prevItem.recipe?.steps ?? [])];
        const [removed] = newSteps.splice(stepIndex, 1);
        newSteps.splice(stepIndex + 1, 0, removed);
        return {
          ...initalBatch,
          ...prevItem,
          recipe: {
            ...initalRecipe,
            ...prevItem.recipe,
            steps: newSteps,
          },
        };
      }
    });
  };

  let day = 0;

  return (
    <ContentLayout
      disableOverlap
      header={
        <Header
          actions={
            <SpaceBetween direction="horizontal" size="xs">
              <Button
                onClick={() => window.history.back()}
                formAction="none"
                variant="link"
              >
                Cancel
              </Button>
              <Button onClick={() => submitForm()} variant="primary">
                Submit
              </Button>
              <Button onClick={() => submitForm(true)} variant="primary">
                Save
              </Button>
            </SpaceBetween>
          }
          variant="h1"
          description={"Make changes to batch recipe"}
        >
          {item?.name ?? "..."}
        </Header>
      }
    >
      <div style={{ marginTop: 10 }}>
        <Form>
          <SpaceBetween direction="vertical" size="l">
            <FormField label="Name">
              <Input readOnly value={item?.recipe?.name ?? ""} />
            </FormField>
            <FormField label="Style">
              <Input readOnly value={item?.recipe?.style ?? ""} />
            </FormField>

            <FormField label="Batch size">
              <Input
                invalid={validate && !item?.recipe?.batchSize}
                value={batchSize}
                onChange={(event) => {
                  const newValue = event.detail.value;
                  setBatchSize(newValue);

                  setItem((prevItem) => {
                    if (prevItem === null) {
                      return null;
                    } else {
                      const newRecipe = {
                        ...initalRecipe,
                        ...prevItem.recipe,
                        batchSize: parseInt(newValue),
                      };
                      return {
                        ...initalBatch,
                        ...prevItem,
                        recipe: newRecipe,
                      };
                    }
                  });
                }}
              />
            </FormField>

            <FormField label="Description">
              <Textarea readOnly value={item?.recipe?.description ?? ""} />
            </FormField>

            <Button onClick={() => setVisibleI(true)}>Add ingredients</Button>
            {item?.recipe?.ingredients.map((ingredient, i) => (
              <SpaceBetween
                key={ingredient.item.id + "-" + i}
                size="m"
                direction="horizontal"
              >
                <Button
                  iconName="angle-up"
                  disabled={i === 0}
                  onClick={() => shiftIngredientUp(i)}
                  variant="inline-link"
                />

                <Button
                  disabled={i === (item.recipe?.ingredients ?? []).length - 1}
                  iconName="angle-down"
                  onClick={() => shiftIngredientDown(i)}
                  variant="inline-link"
                />

                <span>
                  {ingredient.item.name} {ingredient.quantity}{" "}
                  {ingredient.item.unit}
                </span>
                <Button
                  onClick={() => removeIngredient(i)}
                  variant="inline-link"
                >
                  Remove
                </Button>
              </SpaceBetween>
            ))}

            <Button onClick={() => setVisibleS(true)}>Add step</Button>
            {item?.recipe?.steps.map((step, i) => {
              day = i === 0 ? step.duration : step.duration + day;
              return (
                <SpaceBetween key={i} size="m" direction="horizontal">
                  <Button
                    iconName="angle-up"
                    disabled={i === 0}
                    onClick={() => shiftStepsUp(i)}
                    variant="inline-link"
                  />

                  <Button
                    disabled={i === (item.recipe?.steps ?? []).length - 1}
                    iconName="angle-down"
                    onClick={() => shiftStepsDown(i)}
                    variant="inline-link"
                  />

                  <span>
                    Day {day - step.duration} - {step.name} - {step.duration}{" "}
                    days - {step.temperature}°C - {step.gravity} SG -
                    {step.description}
                  </span>
                  <Button
                    onClick={() => handleEditStep(i)}
                    variant="inline-link"
                  >
                    Edit
                  </Button>
                  <Button onClick={() => removeStep(i)} variant="inline-link">
                    Remove
                  </Button>
                </SpaceBetween>
              );
            })}
          </SpaceBetween>
        </Form>
        <Modal
          onDismiss={() => setVisibleI(false)}
          visible={visibleI}
          footer={
            <Box float="right">
              <SpaceBetween direction="horizontal" size="xs">
                <Button
                  onClick={() => {
                    setVisibleI(false);
                    setAddIngredient("");
                    setAddQuantity("");
                  }}
                  variant="link"
                >
                  Cancel
                </Button>
                <Button
                  variant="primary"
                  onClick={() => {
                    if (addIngredient && addQuantity) {
                      setItem((prevItem) => {
                        if (prevItem === null) {
                          return null;
                        } else {
                          return {
                            ...initalBatch,
                            ...prevItem,
                            recipe: {
                              ...initalRecipe,
                              ...prevItem.recipe,
                              ingredients: [
                                ...(prevItem.recipe?.ingredients ?? []),
                                {
                                  item: ingredients.find(
                                    (i) => i.id === addIngredient
                                  )!,
                                  quantity: parseFloat(addQuantity),
                                },
                              ],
                            },
                          };
                        }
                      });
                      setVisibleI(false);
                      setAddIngredient("");
                      setAddQuantity("");
                    }
                  }}
                >
                  Ok
                </Button>
              </SpaceBetween>
            </Box>
          }
          header="Add ingredients"
        >
          <SpaceBetween direction="vertical" size="l">
            <Autosuggest
              onChange={({ detail }) => setAddIngredient(detail.value)}
              value={addIngredient}
              options={ingredients.map((ingredient) => ({
                value: ingredient.id,
                label: ingredient.name,
              }))}
              ariaLabel="Autosuggest example with values and labels"
              placeholder="Enter value"
              empty="No matches found"
            />
            <Input
              placeholder="Quantity"
              value={addQuantity}
              onChange={({ detail }) => setAddQuantity(detail.value)}
            />
            {addIngredient && (
              <strong style={{ fontSize: 18 }}>
                {parseFloat(addQuantity)}
                {ingredients.find((i) => i.id === addIngredient)?.unit} of{" "}
                {ingredients.find((i) => i.id === addIngredient)?.name}
              </strong>
            )}
          </SpaceBetween>
        </Modal>
        <Modal
          visible={visibleS}
          onDismiss={() => setVisibleS(false)}
          header="Add step"
          footer={
            <Box float="right">
              <SpaceBetween direction="horizontal" size="m">
                <Button
                  onClick={() => {
                    setVisibleS(false);
                    setAddStepName("");
                    setAddStepDescription("");
                    setAddStepDuration("");
                    setAddStepTemperature("");
                    setAddStepGravity("");
                    setEditStep(-1);
                  }}
                  variant="normal"
                >
                  Cancel
                </Button>
                <Button
                  variant="primary"
                  onClick={() => {
                    setItem((prevItem) => {
                      if (prevItem === null) {
                        return null;
                      } else {
                        return {
                          ...initalBatch,
                          ...prevItem,
                          recipe: {
                            ...initalRecipe,
                            ...prevItem.recipe,
                            steps:
                              editStep === -1
                                ? [
                                    ...(prevItem.recipe?.steps ?? []),
                                    {
                                      name: addStepName,
                                      description: addStepDescription,
                                      duration: parseInt(addStepDuration),
                                      temperature: parseInt(addStepTemperature),
                                      gravity: parseFloat(addStepGravity),
                                    },
                                  ]
                                : [
                                    ...(prevItem.recipe?.steps ?? []).slice(
                                      0,
                                      editStep
                                    ),
                                    {
                                      name: addStepName,
                                      description: addStepDescription,
                                      duration: parseInt(addStepDuration),
                                      temperature: parseInt(addStepTemperature),
                                      gravity: parseFloat(addStepGravity),
                                    },
                                    ...(prevItem.recipe?.steps ?? []).slice(
                                      editStep + 1
                                    ),
                                  ],
                          },
                        };
                      }
                    });
                    setVisibleS(false);
                    setAddStepName("");
                    setAddStepDescription("");
                    setAddStepDuration("");
                    setAddStepTemperature("");
                    setAddStepGravity("");
                    setEditStep(-1);
                  }}
                >
                  Add
                </Button>
              </SpaceBetween>
            </Box>
          }
        >
          <SpaceBetween direction="vertical" size="l">
            <Input
              placeholder="Name"
              value={addStepName}
              onChange={({ detail }) => setAddStepName(detail.value)}
            />
            <Input
              placeholder="Description"
              value={addStepDescription}
              onChange={({ detail }) => setAddStepDescription(detail.value)}
            />
            <Input
              placeholder="Duration"
              value={addStepDuration}
              onChange={({ detail }) => setAddStepDuration(detail.value)}
            />
            <Input
              placeholder="Temperature"
              value={addStepTemperature}
              onChange={({ detail }) => setAddStepTemperature(detail.value)}
            />
            <Input
              placeholder="Gravity"
              value={addStepGravity}
              onChange={({ detail }) => setAddStepGravity(detail.value)}
            />
          </SpaceBetween>
        </Modal>
      </div>
    </ContentLayout>
  );
};

export default RecipePage;
