import { collection, getDocs } from "@firebase/firestore";

import InventoryItem from "../models/InventoryItem";
import { db } from "../config/firebase";

export const fetchInventoryItems = async (): Promise<InventoryItem[]> => {
  const inventoryCollection = collection(db, "inventory"); // Replace 'inventory' with your actual collection name
  const querySnapshot = await getDocs(inventoryCollection);
  const items: InventoryItem[] = querySnapshot.docs.map(
    (doc) =>
      ({
        ...doc.data(),
        id: doc.id,
      } as InventoryItem)
  );
  return items;
};

export const fetchInventoryItem = async (
  id: string
): Promise<InventoryItem | null> => {
  const inventoryCollection = collection(db, "inventory"); // Replace 'inventory' with your actual collection name
  const querySnapshot = await getDocs(inventoryCollection);
  const item = querySnapshot.docs
    .map((doc) => ({ ...doc.data(), id: doc.id } as InventoryItem))
    .find((item) => item.id === id);
  return item ?? null;
};

export const getInventoryItemCount = async (): Promise<number> => {
  const inventoryCollection = collection(db, "inventory"); // Replace 'inventory' with your actual collection name
  const querySnapshot = await getDocs(inventoryCollection);
  return querySnapshot.size;
};
