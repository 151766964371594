import { logout, signInWithGoogle } from "../auth";

import { Button } from "@cloudscape-design/components";
import React from "react";
import { useAuthContext } from "../contexts/AuthContext";

const Loginout = () => {
  const { currentUser } = useAuthContext();
  return (
    <div style={{ paddingLeft: 28 }}>
      {currentUser ? (
        <Button onClick={logout} variant="inline-link">
          Logout
        </Button>
      ) : (
        <Button onClick={signInWithGoogle} variant="inline-link">
          Login
        </Button>
      )}
    </div>
  );
};

export default Loginout;
