import {
  collection,
  deleteDoc,
  doc,
  getDocs,
  onSnapshot,
} from "@firebase/firestore";

import { Recipe } from "../models/Recipe"; // Import your Recipe type
import { db } from "../config/firebase"; // Adjust this import to your Firebase config file

export const fetchRecipes = async (): Promise<Recipe[]> => {
  try {
    const querySnapshot = await getDocs(collection(db, "recipes"));
    const fetchedRecipes: Recipe[] = querySnapshot.docs.map(
      (doc) =>
        ({
          ...doc.data(),
          id: doc.id,
        } as Recipe)
    );
    return fetchedRecipes;
  } catch (error) {
    console.error("Error fetching recipes:", error);
    throw new Error("Failed to fetch recipes");
  }
};

export const fetchRecipe = async (id: string): Promise<Recipe | null> => {
  try {
    const querySnapshot = await getDocs(collection(db, "recipes"));
    const fetchedRecipe = querySnapshot.docs
      .map((doc) => ({ ...doc.data(), id: doc.id } as Recipe))
      .find((recipe) => recipe.id === id);
    return fetchedRecipe ?? null;
  } catch (error) {
    console.error("Error fetching recipe:", error);
    throw new Error("Failed to fetch recipe");
  }
};

export const subscribeToRecipes = (onUpdate: (recipes: Recipe[]) => void) => {
  const collectionRef = collection(db, "recipes");

  const unsubscribe = onSnapshot(
    collectionRef,
    (querySnapshot) => {
      const fetchedRecipes: Recipe[] = querySnapshot.docs.map(
        (doc) =>
          ({
            ...doc.data(),
            id: doc.id,
          } as Recipe)
      );
      onUpdate(fetchedRecipes);
    },
    (error) => {
      console.error("Error fetching recipes:", error);
    }
  );

  return unsubscribe;
};

export const deleteRecipe = async (id: string) => {
  try {
    const itemRef = doc(db, "recipes", id);
    await deleteDoc(itemRef);
  } catch (error) {
    console.error("Error deleting recipe:", error);
    throw new Error("Failed to delete recipe");
  }
};

export const getRecipeCount = async (): Promise<number> => {
  const querySnapshot = await getDocs(collection(db, "recipes"));
  return querySnapshot.size;
};
