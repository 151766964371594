import { Box, ContentLayout, Header } from "@cloudscape-design/components";
import React, { FC } from "react";

const NotApproved: FC = () => {
  return (
    <ContentLayout
      header={
        <>
          <Header>Account Awaiting Approval</Header>
          <Box variant="awsui-key-label" margin={{ top: "xl" }}>
            Your account has been created but is pending approval from an
            administrator. info@horriblemeadery.com .Please check back later or
            contact support if you have any questions.
          </Box>
        </>
      }
    ></ContentLayout>
  );
};

export const Denied = () => {
  return (
    <ContentLayout
      header={
        <Header description="Your account has been denied. Please contact support (Mr. Sheen) if you have any questions.">
          Account Denied
        </Header>
      }
    >
      <Box variant="awsui-key-label">
        Your account has been denied. Please contact support if you have any
        questions.
      </Box>
    </ContentLayout>
  );
};

export default NotApproved;
