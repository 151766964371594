import { db } from "../config/firebase";
import { collection, getDocs, writeBatch } from "@firebase/firestore";
import { IElo, rate } from "../utils/elo";
import { doc } from "@firebase/firestore";

export interface MenuItem extends IElo {
  title: string; // Display name of the menu item
  type: string; // Type of the menu item, e.g., 'link', 'divider'
  href?: string; // Optional URL or link destination, relevant for 'link' type items
}

export async function updateRatings<T extends IElo>(
  winnerId: T["id"],
  type: string
): Promise<T[]> {
  const playersCol = collection(db, type);
  const snapshot = await getDocs(playersCol);
  const players: IElo[] = snapshot.docs.map((doc) => ({
    ...(doc.data() as IElo),
    id: doc.id,
  }));

  // Find the index of the winner
  const winnerIndex = players.findIndex((player) => player.id === winnerId);
  if (winnerIndex === -1) {
    throw new Error("Winner not found in the player list.");
  }

  // Apply the Elo rate function
  const updatedPlayers = rate(players, winnerId);

  // Start a batch write operation to update all players
  const batch = writeBatch(db);
  updatedPlayers.forEach((player) => {
    const playerRef = doc(db, type, player.id);
    batch.update(playerRef, { ranking: player.ranking });
  });

  // Commit the batch
  await batch.commit();

  return updatedPlayers as T[];
}

export const fetchMenuItems = async (): Promise<MenuItem[]> => {
  try {
    const menuCollection = collection(db, "menuItems");
    const existingItemsSnapshot = await getDocs(menuCollection);
    const existingItems: MenuItem[] = [];
    existingItemsSnapshot.forEach((doc) => {
      // Assuming we use 'href' as the unique identifier for each menu item
      existingItems.push({ ...doc.data(), id: doc.id } as MenuItem);
    });

    return existingItems;
  } catch (error) {
    console.error("Error fetching menuItems:", error);
    throw new Error("Failed to fetch menuItems");
  }
};
