import "@cloudscape-design/global-styles/index.css";

import AbvCalculator from "./components/AbvCalculator";
import { AppLayout } from "@cloudscape-design/components";
import Approve from "./layout/Approve";
import { AuthContextProvider } from "./contexts/AuthContext";
import Hint from "./components/Hint";
import HoneyCalculator from "./components/HoneyCalculator";
import Loginout from "./layout/Loginout";
import { BrowserRouter as Router } from "react-router-dom";
import { Routing } from "./layout/Routing";
import Sidebar from "./layout/Sidebar";
import { initializeMenuItems } from "./api/init";

initializeMenuItems();

function App() {
  return (
    <AuthContextProvider>
      <Router>
        <AppLayout
          navigation={
            <>
              <Sidebar />
              <Approve />
              <Loginout />
            </>
          }
          content={<Routing />}
          tools={
            <div style={{ marginTop: 40, padding: 8 }}>
              <Hint />
              <AbvCalculator />
              <HoneyCalculator />
            </div>
          }
        />
      </Router>
    </AuthContextProvider>
  );
}

export default App;
