import {
  Box,
  ExpandableSection,
  FormField,
  Input,
  SpaceBetween,
} from "@cloudscape-design/components";
import React, { useEffect, useState } from "react";

import { useLocalStorage } from "react-use";
import Disabled from "./Disabled";

const HoneyCalculator: React.FC = () => {
  const [currentGravity, setCurrentGravity] = useState<string>("1.000");
  const [originalGravity, setOriginalGravity] = useState<string>("1.000");
  const [addedHoney, setAddedHoney] = useState<string>("0.00");
  const [waterVolume, setWaterVolume] = useState<string>("25");

  const [expanded, setExpanded] = useLocalStorage("expandedHoney", true);

  var honeyGravity = 1.42; // Specific gravity of honey
  var honeyDensity = 1.36; // Density of honey in kg/L

  const calculateNewGravity = (
    originalGravity: number,
    originalVolume: number,
    honeyMass: number
  ) => {
    // Calculate the volume of honey in liters
    var honeyVolume = honeyMass / honeyDensity;

    // Calculate the total volume after adding honey
    var totalVolume = originalVolume + honeyVolume;

    // Calculate the new gravity
    var newGravity =
      (originalGravity * originalVolume) / totalVolume +
      (honeyGravity * honeyVolume) / totalVolume;

    return newGravity;
  };

  useEffect(() => {
    if (currentGravity && waterVolume)
      setOriginalGravity(
        calculateNewGravity(
          parseFloat(currentGravity),
          parseFloat(waterVolume),
          parseFloat(addedHoney)
        ).toFixed(3)
      );
  }, [currentGravity, waterVolume, addedHoney]);

  return (
    <ExpandableSection
      expanded={expanded}
      onChange={({ detail }) => setExpanded(detail.expanded)}
      headerText="Honey Calculator"
    >
      <Box>
        This helps you estimate how much honey you need to add to reach a
        gravity.
      </Box>
      <SpaceBetween size="m">
        <FormField label="Current gravity">
          <Input
            value={currentGravity}
            onChange={({ detail }) => setCurrentGravity(detail.value)}
          />
        </FormField>
        <FormField label="Must vol (L.)">
          <Input
            value={waterVolume}
            onChange={({ detail }) => setWaterVolume(detail.value)}
          />
        </FormField>
        <FormField label="Honey (kg.)">
          <Input
            value={addedHoney}
            onChange={({ detail }) => setAddedHoney(detail.value)}
          />
        </FormField>
        <Box variant="h3" fontWeight="heavy">
          <span>Estimated gravity: {originalGravity}</span>
        </Box>
      </SpaceBetween>
    </ExpandableSection>
  );
};

export default HoneyCalculator;
