import React from "react";
import { Spinner } from "@cloudscape-design/components";

type LoaderProps = {
  isLoading: boolean;
  children: React.ReactNode;
};

const Loader: React.FC<LoaderProps> = ({ isLoading, children }) => {
  return isLoading ? <Spinner variant="normal" /> : <>{children}</>;
};

export default Loader;
