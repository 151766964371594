import {
  Box,
  ColumnLayout,
  Container,
  ContentLayout,
  Header,
  SpaceBetween,
} from "@cloudscape-design/components";
import { logout, signInWithGoogle } from "../auth";

import ImgBatches from "../assets/images/routes/batches.png";
import ImgInventory from "../assets/images/routes/inventory.png";
import ImgLogin from "../assets/images/routes/login.png";
import ImgLogout from "../assets/images/routes/logout.png";
import ImgRecipes from "../assets/images/routes/recipes.png";
import Loader from "../components/Loader";
import { NavLink } from "react-router-dom";
import React from "react";
import { getBatchCount } from "../api/batches";
import { getInventoryItemCount } from "../api/ingredients";
import { getRecipeCount } from "../api/recipes";
import { useAuthContext } from "../contexts/AuthContext";
import { useWindowSize } from "react-use";

export const routes = [
  {
    path: "/Inventory",
    name: "Inventory",
    icon: <img src={ImgInventory} alt="Inventory icon" />,
    description:
      "Manage your inventory by adding, removing, and updating items",
  },
  {
    path: "/Recipes",
    name: "Recipes",
    icon: <img src={ImgRecipes} alt="Recipies icon" />,
    description: "Manage your recipes by adding, removing, and updating items",
  },
  {
    path: "/Batches",
    name: "Batches",
    icon: <img src={ImgBatches} alt="Batches icon" />,
    description: "Manage your active batches by starting and updating them",
  },
  // {
  //   path: "/QA",
  //   name: "QA",
  //   icon: <Icon name="status-warning" />,
  //   description: "Manage your QA by adding, addressing, and updating issues",
  // },
  // {
  //   path: "/Reports",
  //   name: "Reports",
  //   icon: <Icon name="search" />,
  //   description: "Manage your reports by creating, viewing, and updating",
  // },
  // {
  //   path: "/Settings",
  //   name: "Settings",
  //   icon: <Icon name="settings" />,
  //   description: "Manage your settings by configuring your account",
  // },
];

const Home = () => {
  const { currentUser } = useAuthContext();
  const { width } = useWindowSize();
  const isMobile = width <= 767;
  const [isLoading, setIsLoading] = React.useState(true);
  const [counts, setCounts] = React.useState({
    inventory: 0,
    recipes: 0,
    batches: 0,
    qa: 0,
    reports: 0,
    settings: 0,
  });

  const getCountByPath = (path: string) => {
    switch (path) {
      case "/Inventory":
        return counts.inventory;
      case "/Recipes":
        return counts.recipes;
      case "/Batches":
        return counts.batches;
      case "/QA":
        return counts.qa;
      case "/Reports":
        return counts.reports;
      case "/Settings":
        return counts.settings;
      default:
        return 0;
    }
  };

  const setCountByPath = (c: any, path: string, count: number) => {
    switch (path) {
      case "/Inventory":
        c.inventory = count;
        break;
      case "/Recipes":
        c.recipes = count;
        break;
      case "/Batches":
        c.batches = count;
        break;
      case "/QA":
        c.qa = count;
        break;
      case "/Reports":
        c.reports = count;
        break;
      case "/Settings":
        c.settings = count;
        break;
      default:
        break;
    }
    return c;
  };

  const getApiByPath = (path: string): Promise<number> | null => {
    switch (path) {
      case "/Inventory":
        return getInventoryItemCount();
      case "/Recipes":
        return getRecipeCount();
      case "/Batches":
        return getBatchCount();

      default:
        return null;
    }
  };

  const getCounts = async () => {
    const promises: (Promise<number> | null)[] = [];
    routes.forEach((route) => {
      const promise = getApiByPath(route.path);
      if (promise) {
        promises.push(promise);
      }
    });
    const results = await Promise.all(
      promises.filter((p) => p !== null) as Promise<number>[]
    );
    const newCounts = { ...counts };
    results.forEach((result, index) => {
      setCountByPath(newCounts, routes[index].path, result);
    });
    setCounts(newCounts);
    setIsLoading(false);
  };

  React.useEffect(() => {
    if (!currentUser) {
      setIsLoading(false);
      return;
    }
    getCounts();
  }, [currentUser]);

  return (
    <ContentLayout
      disableOverlap
      header={
        <Header variant="h1" description={`${process.env.REACT_APP_TITLE}`}>
          Producery
        </Header>
      }
    >
      <Loader isLoading={isLoading}>
        <div style={{ marginTop: 10 }}>
          <ColumnLayout columns={isMobile ? 1 : 2} variant="default">
            {currentUser &&
              routes.map((route) => (
                <NavLink
                  key={route.name}
                  to={route.path}
                  style={{ textDecoration: "none" }}
                >
                  <Container
                    className="pointer h-100"
                    media={{
                      content: route.icon,
                      position: "side",
                      width: "40%",
                    }}
                  >
                    <SpaceBetween direction="vertical" size="s">
                      <SpaceBetween direction="vertical" size="xxs">
                        <Box variant="h2">{route.name}</Box>
                        <Box variant="small">
                          {getCountByPath(route.path)} items
                        </Box>
                      </SpaceBetween>
                      <Box variant="p">{route.description}</Box>
                    </SpaceBetween>
                  </Container>
                </NavLink>
              ))}

            {currentUser ? (
              <div className="h-100" onClick={() => logout()}>
                <Container
                  className="pointer h-100"
                  media={{
                    content: <img src={ImgLogout} alt="Logout icon" />,
                    position: "side",
                    width: "40%",
                  }}
                >
                  <SpaceBetween direction="vertical" size="s">
                    <SpaceBetween direction="vertical" size="xxs">
                      <Box variant="h2">Logout</Box>
                    </SpaceBetween>
                  </SpaceBetween>
                </Container>
              </div>
            ) : (
              <div onClick={() => signInWithGoogle()}>
                <Container
                  className="pointer h-100"
                  media={{
                    content: <img src={ImgLogin} alt="Login icon" />,
                    position: "side",
                    width: "40%",
                  }}
                >
                  <SpaceBetween direction="vertical" size="s">
                    <SpaceBetween direction="vertical" size="xxs">
                      <Box variant="h2">Login</Box>
                    </SpaceBetween>
                  </SpaceBetween>
                </Container>
              </div>
            )}
          </ColumnLayout>
        </div>
      </Loader>
    </ContentLayout>
  );
};

export default Home;
