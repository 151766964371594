import {
  Button,
  ContentLayout,
  Header,
  Table,
} from "@cloudscape-design/components";
import { Link, useNavigate } from "react-router-dom";
import React, { FC, useEffect, useState } from "react";
import {
  collection,
  deleteDoc,
  doc,
  getDocs,
  updateDoc,
} from "@firebase/firestore";

import InventoryItem from "../models/InventoryItem";
import { db } from "../config/firebase";

const Inventory: FC = () => {
  const navigate = useNavigate();
  const [inventory, setInventory] = useState<InventoryItem[]>([]);

  const fetchItems = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, "inventory"));
      const items: InventoryItem[] = querySnapshot.docs.map((doc) => {
        const itemData = doc.data() as Omit<InventoryItem, "id">;
        return {
          id: doc.id,
          ...itemData,
        };
      });
      setInventory(items);
    } catch (error) {
      console.error("Error fetching inventory items:", error);
    }
  };

  // TODO: Add to item (modal)
  const editItem = async (item: InventoryItem) => {
    try {
      const itemRef = doc(db, "inventory", item.id);
      await updateDoc(itemRef, item);
      // Update the inventory state
      setInventory((x) =>
        x.map((i) => {
          if (i.id === item.id) {
            return item;
          }
          return i;
        })
      );
    } catch (error) {
      console.error("Error updating inventory item:", error);
      // Handle the error (e.g., show an error message to the user)
    }
  };

  const deleteItem = async (item: InventoryItem) => {
    const confirmed = window.confirm(
      `Are you sure you want to delete ${item.name}?`
    );
    if (!confirmed) return;
    try {
      const itemRef = doc(db, "inventory", item.id);
      await deleteDoc(itemRef);
      // Update the inventory state
      setInventory((x) => x.filter((i) => i.id !== item.id));
    } catch (error) {
      console.error("Error deleting inventory item:", error);
      // Handle the error (e.g., show an error message to the user)
    }
  };

  useEffect(() => {
    fetchItems();
  }, []);

  return (
    <ContentLayout
      disableOverlap
      header={
        <Header
          variant="h1"
          description={"Hint: You can add items and set a quantity later"}
          actions={
            <Button
              iconName="add-plus"
              variant="primary"
              onClick={() => navigate("/Inventory/new")}
            >
              Add ingredient
            </Button>
          }
        >
          Inventory
        </Header>
      }
    >
      <div style={{ marginTop: 10 }}>
        <Table
          variant="embedded"
          items={inventory}
          columnDefinitions={[
            {
              header: "Name",
              cell: (item) => (
                <Link to={`/Inventory/${item.id}`}>{item.name}</Link>
              ),
              isRowHeader: true,
            },
            {
              header: "Quantity",
              cell: (item) => (
                <>
                  {item.quantity} {item.unit}
                </>
              ),
            },
            {
              header: "Price per unit",
              cell: (item) => (
                <>
                  €{item.price}/{item.unit}
                </>
              ),
            },
            {
              header: "Value",
              cell: (item) => (
                <>€{((item.price ?? 0) * item.quantity).toFixed(2)}</>
              ),
            },
            {
              header: "Actions",
              cell: (item) => (
                <>
                  <Button
                    iconName="remove"
                    variant="inline-link"
                    onClick={() => deleteItem(item)}
                  />
                </>
              ),
            },
          ]}
        />
      </div>
    </ContentLayout>
  );
};

export default Inventory;
