import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  onSnapshot,
  query,
} from "@firebase/firestore";
import { CostItem } from "../components/StartUpCost";
import { db } from "../config/firebase";

export const createStartUp = async (item: Omit<CostItem, "id">) => {
  try {
    const docRef = await addDoc(collection(db, "startup"), item);
    return docRef.id;
  } catch (error) {
    console.error("Error adding inventory item:", error);
    throw error; // You might want to handle this error differently
  }
};

export const deleteStartUp = async (itemId: string) => {
  try {
    await deleteDoc(doc(db, "startup", itemId));
    console.log("Document successfully deleted!");
  } catch (error) {
    console.error("Error removing document: ", error);
  }
};

export const subscribeToStartUp = (callback: (items: CostItem[]) => void) => {
  const q = query(collection(db, "startup"));

  const unsubscribe = onSnapshot(q, (snapshot) => {
    const items: CostItem[] = snapshot.docs.map(
      (doc) =>
        ({
          ...doc.data(),
          id: doc.id,
        } as CostItem)
    );
    callback(items);
  });
  return unsubscribe;
};
