import { Batch } from "../models/Batch";
import Data from "../data/bottles.json";

export const costTotal = (batch: Batch): number => {
  if (!batch.recipe) {
    return 0;
  }

  const { ingredients } = batch.recipe;

  const totalCost = ingredients.reduce((total, ingredient) => {
    const { item, quantity } = ingredient;
    const addPrice = (item?.price ?? 0) * quantity;
    return total + addPrice;
  }, 0);

  return totalCost;
};

export const costPerUnit = (batch: Batch): number => {
  if (!batch.recipe) {
    return 0;
  }

  const units =
    batch.yield && batch.yield !== 0
      ? batch.yield
      : batch.expectedYield && batch.expectedYield !== 0
      ? batch.expectedYield
      : batch.volume ?? batch.quantity;
  return costTotal(batch) / units;
};

export const costPerBottle = (batch: Batch, bottleId: string): number => {
  if (!batch.recipe) {
    return 0;
  }
  const bottle: any = Data.bottles.find((b: any) => b.id === bottleId);
  const unitCost = costPerUnit(batch);
  return unitCost * bottle.volume + bottle.price;
};
